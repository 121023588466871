var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.post
    ? _c("div", [
        _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.post.title))]),
        _vm._v(" "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.post.body) } }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }