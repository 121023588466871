var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "signup" }, [
    _c("h1", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("common.signUp"))),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n    " + _vm._s(_vm.$t('msg["Sent verification email"]')) + "\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }