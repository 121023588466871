var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.post
    ? _c("div", [
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("router-link", { attrs: { to: "/informations" } }, [
              _c("i", { staticClass: "fas fa-chevron-left" }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("common.posts")))]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.post.title))]),
        _vm._v(" "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.post.body) } }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-5" }, [
          _c(
            "time",
            {
              attrs: {
                itemprop: "datepublished",
                datetime: _vm._f("dateFormat")(_vm.post.publishAt, ""),
              },
            },
            [_vm._v(_vm._s(_vm._f("dateFormat")(_vm.post.publishAt)))]
          ),
        ]),
        _vm._v(" "),
        _vm.post.category
          ? _c(
              "nav",
              {
                staticClass: "breadcrumb",
                attrs: { "aria-label": "breadcrumbs" },
              },
              [
                _c(
                  "ul",
                  [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/posts/" + _vm.serviceId + "/" } },
                          [_vm._v(_vm._s(_vm.$t("common.posts")))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.post.category.parents, function (parent) {
                      return _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to:
                                  "/posts/" +
                                  _vm.serviceId +
                                  "/categories/" +
                                  parent.slug,
                              },
                            },
                            [_vm._v(_vm._s(parent.label))]
                          ),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/posts/" +
                                _vm.serviceId +
                                "/categories/" +
                                _vm.post.category.slug,
                            },
                          },
                          [_vm._v(_vm._s(_vm.post.category.label))]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }