var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar is-transparent" }, [
    _c("div", { staticClass: "navbar-brand" }, [
      _c(
        "a",
        { staticClass: "navbar-item", attrs: { href: "" } },
        [
          _c(
            "router-link",
            { staticClass: "navbar-item", attrs: { to: "/" } },
            [
              _c("img", {
                attrs: {
                  src: "https://bulma.io/images/bulma-logo.png",
                  alt: "Sample Site",
                  width: "112",
                  height: "28",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "navbar-burger burger",
          class: { "is-active": _vm.isHeaderMenuOpen },
          on: {
            click: function ($event) {
              return _vm.toggleHeaderMenuOpen()
            },
          },
        },
        [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "navbar-menu",
        class: { "is-active": _vm.isHeaderMenuOpen },
      },
      [
        _c(
          "div",
          { staticClass: "navbar-start" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-item", attrs: { to: "/" } },
              [_vm._v(_vm._s(_vm.$t("common.top")))]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "navbar-item", attrs: { to: "/about" } },
              [_vm._v(_vm._s(_vm.$t("page.about")))]
            ),
            _vm._v(" "),
            _c("router-link", {
              staticClass: "navbar-item",
              attrs: { to: "/" },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }