var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.posts.length > 0
      ? _c(
          "ul",
          _vm._l(_vm.posts, function (post) {
            return _c("li", { staticClass: "block" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "media" }, [
                    _c("div", { staticClass: "media-content" }, [
                      _c(
                        "h4",
                        { staticClass: "title is-4" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/informations/" + post.slug } },
                            [_vm._v(_vm._s(post.title))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { domProps: { innerHTML: _vm._s(post.body) } }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-5" }, [
                      _c(
                        "time",
                        {
                          attrs: {
                            itemprop: "datepublished",
                            datetime: _vm._f("dateFormat")(post.publishAt, ""),
                          },
                        },
                        [_vm._v(_vm._s(_vm._f("dateFormat")(post.publishAt)))]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          }),
          0
        )
      : _c("div", [_c("p", [_vm._v(_vm._s(_vm.$t('msg["Data is empty"]')))])]),
    _vm._v(" "),
    _vm.hasNext
      ? _c("nav", { staticClass: "u-mt2r" }, [
          _c(
            "a",
            {
              staticClass: "u-clickable",
              on: {
                click: function ($event) {
                  return _vm.fetchPosts({ untilTime: _vm.lastItemPublishedAt })
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.more")))]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }