<template>
<div>
  <section class="hero is-medium is-info">
    <div class="hero-body">
      <h1 class="title">{{ $t('site.name') }}</h1>
      <p class="subtitle">
        Medium subtitle
      </p>
    </div>
  </section>

  <section class="mt-6">
  </section>
</div>
</template>
<script>
export default{
  name: 'Top',

  data(){
    return {
    }
  },

  created() {
  }
}
</script>
