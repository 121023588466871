var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isAdminPath ? _c("eb-admin-navbar") : _c("eb-navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "columns is-desktop" }, [
          _c(
            "main",
            { staticClass: "section column" },
            [
              _c("b-loading", {
                attrs: {
                  active: _vm.isLoading,
                  "is-full-page": true,
                  canCancel: true,
                },
              }),
              _vm._v(" "),
              _c("router-view"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column is-3-desktop is-3-widescreen" },
            [_c("eb-side-nav-menu")],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }