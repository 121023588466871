var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    { staticClass: "file", class: _vm.fieldClass },
    [
      _c(
        "b-upload",
        {
          staticClass: "file-label",
          attrs: { accept: _vm.accept, disabled: _vm.isDisabled },
          model: {
            value: _vm.file,
            callback: function ($$v) {
              _vm.file = $$v
            },
            expression: "file",
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }