var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "field is-horizontal" }, [
    _vm.dispLabel
      ? _c("div", { staticClass: "field-label is-normal" }, [
          _c("label", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$t("common.profilePhoto"))),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "field-body" }, [
      _c("div", { staticClass: "field is-grouped" }, [
        _c("div", { staticClass: "control is-expanded" }, [
          _vm.profilePhotoUrl
            ? _c(
                "span",
                [
                  _c("eb-img", {
                    attrs: {
                      src: _vm.profilePhotoUrl,
                      size: "400x400xs",
                      "size-class": "is-128x128",
                    },
                  }),
                ],
                1
              )
            : _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("msg.Unregistered")) +
                    "\n        "
                ),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "control" }, [
          _vm.isEdit
            ? _c(
                "div",
                [
                  _c(
                    "eb-upload-btn",
                    {
                      attrs: {
                        "btn-class": "is-info",
                        "rel-name": "user_prof",
                      },
                      on: { uploaded: _vm.editComplete },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "file-cta" },
                        [
                          _c("b-icon", {
                            attrs: {
                              pack: "fas",
                              size: "is-small",
                              icon: "camera",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "has-text-weight-medium" },
                            [_vm._v("+")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    { staticClass: "button", on: { click: _vm.cancel } },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _vm.errorMsg
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.errorMsg)),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "a",
                {
                  staticClass: "is-pulled-right u-clickable",
                  on: {
                    click: function ($event) {
                      _vm.isEdit = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("common.edit")))]
              ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }