var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isEdit
      ? _c("div", [
          _c("div", { staticClass: "field is-horizontal" }, [
            _vm.dispLabel
              ? _c("div", { staticClass: "field-label is-normal" }, [
                  _c("label", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("common.password"))),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "field-val" }, [
                  _c("span", { staticClass: "is-italic is-size-6" }, [
                    _vm._v(_vm._s(_vm.$t('form["Masked for security"]'))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "is-pulled-right u-clickable",
                      on: {
                        click: function ($event) {
                          _vm.isEdit = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.edit")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _c(
          "div",
          [
            _c("field-input-password", {
              attrs: {
                label: _vm.$t("form.CurrentPassword"),
                value: _vm.passwordOld,
                errorMsg: _vm.errors.passwordOld,
              },
              on: {
                "update:value": function ($event) {
                  _vm.passwordOld = $event
                },
                blur: function ($event) {
                  return _vm.validate("passwordOld")
                },
              },
            }),
            _vm._v(" "),
            _c("field-input-password", {
              attrs: {
                label: _vm.$t("form.NewPassword"),
                value: _vm.password,
                errorMsg: _vm.errors.password,
              },
              on: {
                "update:value": function ($event) {
                  _vm.password = $event
                },
                blur: function ($event) {
                  return _vm.validate("password")
                },
              },
            }),
            _vm._v(" "),
            _c("field-input-password", {
              attrs: {
                label: _vm.$t("form.RetypeNewPassword"),
                value: _vm.passwordRetype,
                errorMsg: _vm.errors.passwordRetype,
              },
              on: {
                "update:value": function ($event) {
                  _vm.passwordRetype = $event
                },
                blur: function ($event) {
                  return _vm.validate("passwordRetype")
                },
              },
            }),
            _vm._v(" "),
            _vm.errorGlobal
              ? _c("p", { staticClass: "has-text-danger" }, [
                  _vm._v(_vm._s(_vm.errorGlobal)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "field is-grouped is-grouped-right" }, [
              _c("div", { staticClass: "control" }, [
                _c(
                  "a",
                  {
                    staticClass: "button is-info",
                    attrs: { disabled: _vm.hasErrors },
                    on: { click: _vm.edit },
                  },
                  [_vm._v(_vm._s(_vm.$t("common.edit")))]
                ),
                _vm._v(" "),
                _c("a", { staticClass: "button", on: { click: _vm.cancel } }, [
                  _vm._v(_vm._s(_vm.$t("common.cancel"))),
                ]),
              ]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }