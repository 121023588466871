var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { staticClass: "menu" }, [
    _c("p", { staticClass: "menu-label" }, [_vm._v("\n    General\n  ")]),
    _vm._v(" "),
    _vm.pages
      ? _c(
          "ul",
          { staticClass: "menu-list" },
          [
            _vm._l(_vm.pages, function (page) {
              return page.slug != "top"
                ? _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/" + page.slug } }, [
                        _vm._v(_vm._s(page.title)),
                      ]),
                    ],
                    1
                  )
                : _vm._e()
            }),
            _vm._v(" "),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/informations" } }, [
                  _vm._v("お知らせ"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [_c("router-link", { attrs: { to: "/" } }, [_vm._v("トップ")])],
              1
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }