<template>
<div>
  <h1 class="title">{{ $t('error.403') }}</h1>
  <p>{{ $t('msg["Required Email Verification"]') }}</p>
</div>
</template>

<script>

export default {
  name: 'RequiredEmailVerification',

  data () {
    return {
    }
  },

  computed: {
  },

  created: function() {
  },

  methods: {
  }
}
</script>

