var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isEdit
      ? _c("div", [
          _c("div", { staticClass: "field is-horizontal" }, [
            _vm.dispLabel
              ? _c("div", { staticClass: "field-label is-normal" }, [
                  _c("label", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("common.email"))),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "field-body" }, [
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "field-val" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$store.getters.userInfo("email"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "is-pulled-right u-clickable",
                      on: {
                        click: function ($event) {
                          _vm.isEdit = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.edit")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _c(
          "div",
          [
            _c("div", { staticClass: "field is-horizontal" }, [
              _c("div", { staticClass: "field-label is-normal" }, [
                _c("label", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("common.email"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field-body" }, [
                _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    { staticClass: "control has-icons-left has-icons-right" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        staticClass: "input",
                        class: { "is-danger": _vm.errors.email },
                        attrs: {
                          type: "email",
                          placeholder: _vm.$t("common.email"),
                        },
                        domProps: { value: _vm.email },
                        on: {
                          blur: function ($event) {
                            return _vm.validate("email")
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _vm.errors.email
                        ? _c(
                            "span",
                            { staticClass: "icon is-small is-right" },
                            [
                              _c("i", {
                                staticClass: "fas fa-exclamation-triangle",
                                class: { "has-text-danger": _vm.errors.email },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errors.email
                    ? _c("p", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.errors.email)),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("field-input-password", {
              attrs: {
                label: _vm.$t("common.password"),
                value: _vm.password,
                errorMsg: _vm.errors.password,
              },
              on: {
                "update:value": function ($event) {
                  _vm.password = $event
                },
                blur: function ($event) {
                  return _vm.validate("password")
                },
              },
            }),
            _vm._v(" "),
            _vm.errorGlobal
              ? _c("p", { staticClass: "has-text-danger" }, [
                  _vm._v(_vm._s(_vm.errorGlobal)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "field is-grouped is-grouped-right" }, [
              _c("div", { staticClass: "control" }, [
                _c(
                  "a",
                  {
                    staticClass: "button is-info",
                    attrs: { disabled: !_vm.isChanged || _vm.hasErrors },
                    on: { click: _vm.edit },
                  },
                  [_vm._v(_vm._s(_vm.$t("common.edit")))]
                ),
                _vm._v(" "),
                _c("a", { staticClass: "button", on: { click: _vm.cancel } }, [
                  _vm._v(_vm._s(_vm.$t("common.cancel"))),
                ]),
              ]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-envelope" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }