var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("page.userTop"))),
    ]),
    _vm._v(" "),
    _vm.isAuth
      ? _c("p", [
          _vm._v(_vm._s(_vm.$t("msg.signInGreeting", { name: _vm.userName }))),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }