var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "hero is-medium is-info" }, [
      _c("div", { staticClass: "hero-body" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("site.name"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "subtitle" }, [
          _vm._v("\n        Medium subtitle\n      "),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "mt-6" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }