var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("page.EmailVerification"))),
    ]),
    _vm._v(" "),
    _vm.resTitle
      ? _c("p", { staticClass: "is-size-5" }, [_vm._v(_vm._s(_vm.resTitle))])
      : _vm._e(),
    _vm.resBody ? _c("p", [_vm._v(_vm._s(_vm.resBody))]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }