<template>
<div class="signup">
  <h1 class="title">{{ $t('common.signUp') }}</h1>
  <p>
    {{ $t('msg["Sent verification email"]') }}
  </p>
</div>
</template>

<script>

export default {
  name: 'SentVerificationMail',

  data () {
    return {
    }
  },

  computed: {
  },

  created: function() {
  },

  methods: {
  }
}
</script>

