var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ebSignInForm" },
    [
      _c(
        "b-field",
        {
          attrs: {
            label: _vm.$t("common.email"),
            type: _vm.checkEmpty(_vm.errors.email) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.email)
              ? ""
              : _vm.errors.email[0],
          },
        },
        [
          _c("b-input", {
            attrs: {
              type: "email",
              icon: "envelope",
              "icon-pack": "fas",
              placeholder: _vm.$t("common.email"),
            },
            on: {
              blur: function ($event) {
                return _vm.validate("email")
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.keyDownEnter($event)
              },
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        {
          attrs: {
            label: _vm.$t("common.password"),
            type: _vm.checkEmpty(_vm.errors.password) ? "" : "is-danger",
            message: _vm.checkEmpty(_vm.errors.password)
              ? ""
              : _vm.errors.password[0],
          },
        },
        [
          _c("b-input", {
            attrs: {
              type: "password",
              "password-reveal": true,
              icon: "lock",
              "icon-pack": "fas",
              placeholder: _vm.$t("common.password"),
            },
            on: {
              blur: function ($event) {
                return _vm.validate("password")
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.keyDownEnter($event)
              },
            },
            model: {
              value: _vm.password,
              callback: function ($$v) {
                _vm.password = $$v
              },
              expression: "password",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "field is-grouped" }, [
        _c("div", { staticClass: "control" }, [
          _c(
            "button",
            {
              staticClass: "button is-link",
              attrs: { disabled: _vm.hasErrors },
              on: { click: _vm.signIn },
            },
            [_vm._v(_vm._s(_vm.$t("common.signIn")))]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field is-grouped" }, [
        _c("div", { staticClass: "control" }, [
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.signInWithOAuth("google.com")
                },
              },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t('form["Sign In with Google"]'))),
              ]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fab fa-google" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }