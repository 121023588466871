var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_vm._m(0), _vm._v(" "), _c("post-list", { staticClass: "mt-6" })],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h1", { staticClass: "title" }, [_vm._v("お知らせ")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }