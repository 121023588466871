var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("common.signUp"))),
      ]),
      _vm._v(" "),
      _c("eb-sign-up-form"),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "u-mt5" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t('msg["Do you have an account?"]')) +
              " \n    "
          ),
          _c("router-link", { attrs: { to: "/signin" } }, [
            _vm._v(_vm._s(_vm.$t('msg["sign in now!!"]'))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }