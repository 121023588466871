import { render, staticRenderFns } from "./UserVerifyEmail.vue?vue&type=template&id=0445c517&"
import script from "./UserVerifyEmail.vue?vue&type=script&lang=js&"
export * from "./UserVerifyEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cms-sample-site/cms-sample-site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0445c517')) {
      api.createRecord('0445c517', component.options)
    } else {
      api.reload('0445c517', component.options)
    }
    module.hot.accept("./UserVerifyEmail.vue?vue&type=template&id=0445c517&", function () {
      api.rerender('0445c517', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/js/templates/UserVerifyEmail.vue"
export default component.exports