var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "field is-horizontal" }, [
    _vm.dispLabel
      ? _c("div", { staticClass: "field-label is-normal" }, [
          _c("label", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$t("common.userName"))),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "field-body" }, [
      _vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "field is-grouped" }, [
              _c("div", { staticClass: "control is-expanded" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name",
                    },
                  ],
                  staticClass: "input",
                  class: { "is-danger": _vm.errorMsg },
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("common.userName"),
                  },
                  domProps: { value: _vm.name },
                  on: {
                    blur: _vm.validate,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "control" }, [
                _c(
                  "a",
                  { staticClass: "button is-info", on: { click: _vm.edit } },
                  [_vm._v(_vm._s(_vm.$t("common.edit")))]
                ),
                _vm._v(" "),
                _c("a", { staticClass: "button", on: { click: _vm.cancel } }, [
                  _vm._v(_vm._s(_vm.$t("common.cancel"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.errorMsg
              ? _c("p", { staticClass: "help is-danger" }, [
                  _vm._v(_vm._s(_vm.errorMsg)),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "field-val" }, [
              _c("span", [_vm._v(_vm._s(_vm.$store.getters.userInfo("name")))]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "is-pulled-right u-clickable",
                  on: {
                    click: function ($event) {
                      _vm.isEdit = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("common.edit")))]
              ),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }